<template>
  <div>
    <div class="standard-page privacy-policy px-4 py-3 px-md-6 py-md-8">
      <!-- show logo and login CTA -->
      <div class="d-flex justify-space-between align-center align-md-start">
        <v-img
          contain
          width="70"
          src="/img/logo.png"
          class="flex-grow-0 cursor-pointer"
          @click="$router.push({ name: 'Home' })"
        />

        <v-spacer />

        <v-btn
          depressed
          outlined
          small
          class="font-weight-bold"
          :to="{ name: 'Login' }"
        >
          {{ isAuthenticated ? "Dashboard" : "Login" }}
        </v-btn>
      </div>

      <h1 class="my-4 my-sm-6 my-md-8 my-lg-12 text-h3 text-lg-h1">
        FAQs
      </h1>

      <h6 class="text-h6 font-weight-bold primary--text text-uppercase my-6">
        Please click on any of these topic for more information
      </h6>

      <v-row>
        <v-col
          v-for="(category, index) in items"
          :key="'category-' + index"
          cols="12"
          md="6"
        >
          <h6 class="text-h6 font-weight-bold text-uppercase my-6">
            {{ category.name }}
            <small class="grey--text">&nbsp;({{ category.questions.length }})</small>
          </h6>

          <ul>
            <li
              v-for="(question, _index) in category.questions"
              :key="'category-' + index + '-question-' + _index"
              class="primary--text pointer"
              @click="openAnswer(question)"
            >
              {{ question.title }}
            </li>
          </ul>
        </v-col>
      </v-row>
    </div>

    <!-- Show the popup dialog here -->
    <v-dialog
      v-model="shouldShowAnswerDialog"
      max-width="800"
      scrollable
    >
      <v-card v-if="answerData">
        <v-card-title>
          {{ answerData.title }}
        </v-card-title>

        <v-card-text>
          <div
            v-for="(line, index) in answerData.body"
            :key="index"
          >
            <!-- If it's a string -->
            <p
              v-if="typeof line === 'string'"
              v-html="line"
            ></p>

            <!-- If it's a list -->
            <ul
              v-if="Array.isArray(line)"
              class="mb-3 mt-2"
            >
              <li
                v-for="(listItem, listIndex) in line"
                :key="`line-${index}-item-${listIndex}`"
                v-html="listItem"
              ></li>
            </ul>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            color="primary"
            @click="shouldShowAnswerDialog = false"
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import the questions from external file
import data from "@/json/faq.js"

// Export the SFC
export default {
  // Name of the component
  name: "FAQ",

  // Local data variables
  data: () => ({
    // Use the data from external file to be used by templates
    items: data,

    // use this to show dialog
    shouldShowAnswerDialog: false,
    answerData: null
  }),

  // Computed data variables
  computed: {
    /**
     * Whether or not the user is logged in
     *
     * @returns {Boolean}
     */
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    }
  },

  // Local method functions
  methods: {
    openAnswer(question) {
      this.answerData = question
      this.shouldShowAnswerDialog = true
    }
  },

  /**
   * As soon as the component renders
   *
   * @returns {void}
   */
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>
