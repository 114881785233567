// Import host data
import host from "@/json/host.js"

// category wise
export default [
  // Category 1: General
  {
    name: "General",
    questions: [
      {
        title: "What products do you offer?",
        body: [
          // Line 1
          `${host.name} is an AI-driven analytics platform that offers in-depth insights into the demographics and psychographics of influencers and their audiences on <b>Instagram, YouTube and TikTok</b>.`,

          // Line 2
          `We are pleased to offer the following products:`,

          // Line 3
          `1) Influencer Insight - offers demographics, psychographics and performance insights into the accounts 90,084,807 Instagram, 57 million TikTok and 7 million YouTube influencers: <a href="/influencer-insight" target="_blank">Influencer Insight</a>`,

          // Line 4
          `2) Influencer Discovery - helps you find the most relevant talent for your campaign based on the parameters of both influencers and their audiences; there are 106,770,718 Instagram, 57 million TikTok and 7 million YouTube influencers to choose from: <a href="/influencer-discovery" target="_blank">Influencer Discovery</a>`,

          // Line 5
          `3) Paid Collaborations - help you identify sponsored posts on Instagram based on the #hashtags or @mentions they contain or their sponsor; you can find all the sponsored posts for any given location over any time period: <a href="/paid-collaborations" target="_blank">Paid Collaborations</a>.`
        ]
      },
      {
        title: "Is it possible to test the tools for free?",
        body: [
          `Yes, ${host.name} is based on a freemium model.`,
          `All you need to do is <a href="/register" target="_blank">sign up</a>, wait for your account to be approved by our managers (which usually takes only a few minutes), receive 10 trial tokens and test the platform for free. No credit card is required upon the sign-up.`
        ]
      },
      {
        title: "How have you obtained this data?",
        body: [
          "We honour the privacy and security of social network users, which is why we only provide aggregated anonymised data.",
          "Audience Data metrics are based on the public data that is available on the Instagram/YouTube/TikTok. Based on our algorithms we calculate different data points.",
          `For further information, please refer to <a href="/articles">articles</a>`
        ]
      },
      {
        title: "Do you provide audience data on other social media platforms?",
        body: [
          "Yes, we have audience data on TikTok and YouTube influencers as well."
        ]
      },
      {
        title: "Do you provide reports in PDF format?",
        body: [
          "Yes, our reports can be downloaded as PDF"
        ]
      }
    ]
  },
  {
    name: "Paid Collaborations",
    questions: [
      {
        title: "What is Paid Collaborations Tool?",
        body: [
          `The Paid Collaborations Tool allows you to identify sponsored posts on Instagram based on the #hashtags or @mentions they contain or their sponsor.`,

          `The tool can be used to track your own influencer marketing activity; to check your competitors' campaigns; to discover what brands are actively investing into influencer marketing and might have potential for collaboration.`,

          `You can discover all the sponsored posts for any given location over any time period:`,

          `The tool is available <a href="/paid-collaborations" target="_blank">here</a>`
        ]
      },

      {
        title: "What posts can I find through Paid Collaborations?",
        body: [
          "Paid Collaborations Tool allows you to discover any sponsored posts that have not been deleted by their author.",

          `<b>Paid Collaborations</b> are the posts that:`,

          [
            "either are disclosed as such by the influencer through the Paid Partnership feature;",
            "or contain some of the hashtags that can help us identify them as sponsored (#ad, #sponsored, #paid and many others)."
          ]
        ]
      }
    ]
  },
  {
    name: "Influencer Insight",
    questions: [
      {
        title: "What is Influencer Insight?",
        body: [
          "Influencer Insight is a tool that provides you with in-depth demographic & psychographic data on over 100 million Instagram, 50 million TikTok and 6.6 million YouTube influencers, as well as their audience.",

          "Please note that all of our data is proprietary metrics based on estimated data and not necessarily reflective of the actual data."
        ]
      },

      {
        title: "What is the Engagement Rate?",
        body: [
          "It is the average number of likes divided by the number of followers.",

          `It is calculated based on the likes gathered <b>within the last two months</b>, but no more than 500 posts in total. If the influencer does not post often, we may take the posts older than two months, but no more than 10 in total.`
        ]
      },

      {
        title: "What is the Audience Credibility?",
        body: [
          "The Audience Credibility shows the percentage of the audience that we can certainly define as real people (not bots or fake accounts).",

          "To establish the Audience Credibility score we take into account multiple factors: profile pictures, bio descriptions, the number of posts, as well as the follower vs following ratio."
        ]
      },

      {
        title: "What is Paid Post Performance?",
        body: [
          "Paid Post Performance shows how the disclosed sponsored posts of an influencer perform compared to his or her organic posts, in terms of the average number of likes those posts receive.",

          "<b>Example</b>: if you see Paid Post Performance at 90%, it means that this influencer's sponsored posts get 10% less likes than their organic posts. If you see 110%, it means the influencer's sponsored posts get 10% more likes than their organic posts.",

          "You will be surprised that Paid Post Performance can largely differ from 11% to 276%, which means that you can overpay for a post if you base your pricing on the average number of likes. For instance, instead of the 10,000 engagements that the influencer gets on average and that you expect of their sponsored posts as well, you might get only 1,100 likes on your sponsored post."
        ]
      },

      {
        title: "What is the Brand Affinity?",
        body: [
          `We determine brand affinity by analyzing the captions, @mentions, #hashtags and location that are used by either the influencer (<b>Influencer Brand Affinity</b>) or their audience (<b>Audience Brand Affinity</b>).`,

          `Within the Audience Brand Affinity data point, the weight of a brand is measured as a percentage of the audience showing interest in this particular brand. The affinity of a brand is a measure of how much this audience is interested in the brand compared to Instagram users on average - with 1.0 affinity meaning they are just as interested as an average user; 2.0 meaning they are twice as interested; 0.5 meaning they are half as interested, etc.`,

          `Brand affinities are categorised based on the captions, @mentions, #hashtags and location tags in <b>the latest 150 posts</b>; or more than 150 posts if they have been published <b>within the last two months</b>.`,

          `We then categorise the data from captions, etc. into the brands that we have in our database (Apple, Adidas, Disney, Starbucks, etc.).`
        ]
      },

      {
        title: "What are the Audience/Influencer Interests?",
        body: [
          `We determine interests by analysing the captions, @mentions, #hashtags and location that are used by either the influencer (data point <b>Influencer Interests</b>) or their audience (data point <b>Audience Interest Affinity</b>).`,

          `Within the Audience Interest Affinity data point, the weight of an interest is measured as a percentage of the audience interested in this particular topic. The affinity of an interest is a measure of how much this audience is interested in the topic compared to Instagram users on average - with 1.0 affinity meaning they are just as interested as an average user; 2.0 meaning they are twice as interested; 0.5 meaning they are half as interested, etc.`,

          `Interests are categorised based on the captions, @mentions, #hashtags and location tags in <b>the latest 150 posts</b>; or more than 150 posts if they have been published <b>within the last two months</b>.`,

          `We then categorise the data from captions, etc. into Interests (Sports; Beauty&Cosmetics; Travel, Tourism & Aviation, and so on).`
        ]
      },

      {
        title: "What are Sponsored and Popular Posts?",
        body: [
          `<b>Sponsored Posts</b> are the posts that:`,

          [
            `either are disclosed as such by the influencer through the Paid Partnership feature;`,
            `or contain some of the 38 hashtags that can help us identify them as sponsored (#ad, #sponsored, #paid and many others).`,
          ],

          `<b>Popular Posts</b> are the influencer's posts that gathered the largest absolute number of likes. They are shown based on the likes gathered by <b>the latest 150 posts</b>; or more than 150 posts if they have been published <b>within the last two months</b>.`
        ]
      },

      {
        title: "Do you provide Audience Data for any influencer?",
        body: [
          "We provide Audience Data for any public account registered on Instagram, YouTube and TikTok with over 1,000 followers",
          "If an account you are interested in has fewer than 1,000 followers, you can ask us to run a customized report. "
        ]
      },

      {
        title: "How often is the data updated?",
        body: [
          "We fully update our estimated audience data on a monthly basis.",
          `<b>Note</b>: the following data points are updated at different rates:`,
          "1) Follower Count - biweekly",
          "2) Engagement Rate - biweekly",
          "3) Followers / Following Charts - biweekly",
          "4) Recent Posts - weekly"
        ]
      },

      {
        title: "Is it possible to white label the reports?",
        body: [
          "Yes, all our reports are white-labeled.",
          "We can also add your own logo to all of the audience data reports that you generate."
        ]
      },

      {
        title: `How different are ${host.name}'s metrics from the actual social network statistics?`,
        body: [
          "Please note that all of our data is proprietary metrics based on estimated data and not necessarily reflective of the actual data."
        ]
      },

      {
        title: "How do you determine Location?",
        body: [
          `The location of an influencer (<b>Location</b>) or their audience (<b>Location by City; Location by Country</b>) is determined by analyzing the respective location tags, the languages of their bio descriptions and captions under their recent posts.`,

          `The location tags and captions are gathered from the <b>latest 150 posts</b>; or more than 150 posts if they have been published <b>within the last two months</b>.`,

          `Based on location tags, a time-map is created for both influencers and their audiences: it shows what location (country or city) covers the largest timeframe. That way we make sure that even if a person travels a lot (like celebrities or travel bloggers do), but keeps coming back to the same place - this particular country or city will be determined as their location. `
        ]
      },

      {
        title: "What are the Average Likes / Comments / Views?",
        body: [
          "The Average Likes, Comments and Views are the average sum of likes/comments/views of videos under the influencer's latest posts. ",

          `It is calculated based on the likes, comments and views gathered <b>within the last two months</b>, but no more than 500 posts in total. If the influencer does not post often, we may take the posts older than two months, but no more than 10 in total. The latest 150 posts are stored in our database.`
        ]
      },

      {
        title: "What are Lookalikes?",
        body: [
          `Lookalikes are the influencers that either post similar content (Influencer Lookalikes) or have a similar / overlapping audience (Audience Lookalikes)`,

          `1) <b>Influencer Lookalikes</b> are the influencers whose cloud of interest overlaps with the cloud of the influencer in question. Use lists of these lookalikes with influencers that performed well in the past and build your new campaigns around the influencers with similar interests and content.`,

          `2) <b>Audience Lookalikes</b> are based on the accounts that follow similar pages, meaning that the followers of audience lookalikes are very likely to follow the influencer in question. This feature can be used either to target the same audience you have been successful with in the past, or to diversify your targeting and help you understand what influencers you should not to work with if you are trying to find new audiences.`
        ]
      },

      {
        title: "What is the Audience Reachability?",
        body: [
          `Audience Reachability shows the <b>percentage of mass-followers</b> within the audience of an influencer.`,

          `It shows the percentage of the audience that follows more than 1,500 accounts; from 1,000 to 1,500 accounts; from 500 to 1,000 accounts; less than 500 accounts.`,

          `The audience following more than 1,500 other accounts is unlikely to see the sponsored posts done by the influencer in question. They are also quite likely to be bots or fake accounts.`
        ]
      },

      {
        title: "How do you determine Gender?",
        body: [
          `Gender Split is determined by analyzing profile pictures and latest selfies with the help of our photo analysis technology; checking names and bio descriptions (if relevant).`,

          `Audience Gender is available only as an aggregated percentage.`
        ]
      },

      {
        title: "How do you determine Age?",
        body: [
          "Age Split is determined by analyzing profile pictures and latest selfies with the help of our photo analysis technology and checking bio descriptions (if relevant).",

          "Audience Age is available only as an aggregated percentage."
        ]
      },

      {
        title: "How do you determine Language?",
        body: [
          "Language is determined by analyzing bio descriptions and captions of recent posts."
        ]
      },

      {
        title: "How can I find the reports I have already run?",
        body: [
          "The history of your unlocked influencer profiles are available under 'Generated Reports' section of the app"
        ]
      },

      {
        title: "What are Popular Hashtags?",
        body: [
          `The Popular Hashtags are the hashtags (#) that have been most frequently used under the <b>latest 30 posts</b>.`
        ]
      },

      {
        title: "What are Popular Mentions?",
        body: [
          `The Popular Mentions are the mentions (@) that have been most frequently used under the <b>latest 30 posts</b>.`
        ]
      },

      {
        title: "What is an Audience Overlap?",
        body: [
          "The Audience Overlap allows you to discover the percentages of unique and overlapping followers for two or more influencers.",
          "For each influencer analyzed, the feature shows:",

          [
            "Unique Percentage - the percent of followers that are unique for this influencer (they do not follow any other influencer within this overlap request);",
            "Overlapping Percentage - the percent of followers that overlap with at least one other influencer (they follow at least one other influencer within this overlap request)."
          ]
        ]
      },

      {
        title: "How big is the Sample Size?",
        body: [
          "Sample generally varies from 10,000 to 20,000. Sample is refreshed every month."
        ]
      }
    ]
  },
  {
    name: "Influencer Discovery",
    questions: [
      {
        title: "What is Influencer Identification?",
        body: [
          `Influencer Identification is the tool that allows you to find influencers based on a topic search and numerous demographic and psychographic parameters. There are over 107,779,666 Instagram, 57,290,735 TikTok and 7,264,946 YouTube influencers in our Influencer Identification database.`,

          `Thanks to our numerous Audience / Influencer filters, the tool helps you find both influencers and audiences based on your specific criteria.`,

          `You can always export the results of your search (with contact details of the influencers).`,

          `And precisely because we analyse millions of accounts globally, you have a chance to find the diamond in the rough - all those micro-influencers that are yet to be discovered by the big brands and agencies.`,

          `The tool is available <a href="/influencer-discovery" target="_blank">here</a>`
        ]
      },

      {
        title: "How do I export an influencer list?",
        body: [
          `Once you are done with your search process - you have applied all the necessary filters and are happy with the results, all you need to do is click on Download CSV in the top right corner of the page:`
        ]
      },

      {
        title: "How do I find influencers based on topics?",
        body: [
          `The search bar allows you to identify influencers based on the content they post. For example, try searching for “highheels”`,

          `Our search technology mirrors that of Google: we build a cloud of relevance (of everything related to the topic you are looking for) and arrange the results according to their relevance to this topic. `
        ]
      },

      {
        title: "What is the Lookalikes Search?",
        body: [
          `Lookalikes are the influencers that either post similar content (Influencer Lookalikes) or have a similar / overlapping audience (Audience Lookalikes)`,

          `1) <b>Influencer Lookalikes</b>: Use this feature with influencers that performed well in the past and refine your results by applying influencer and audience filters. For example, if @Leomessi has shown great results on your campaigns, you can find influencers whose content is similar to his.`,

          `2) <b>Audience Lookalikes</b>: help you find influencers with overlapping audiences, so if the audience of @Niomismart has reacted well to your campaigns, you can find influencers with the similar / overlapping audiences that can give the same positive results.`,

          "The latter feature can be used to diversify your targeting and help you understand what influencers you should not to work with if you are trying to find new audiences."
        ]
      },

      {
        title: "Is it possible to identify influencers by their bio?",
        body: [
          `Using our Bio filter, you can discover influencers based on the keywords mentioned in their account description.`,

          `<b>Example</b>: If you insert the keyword "stylist" in the Bio filter you will find 138,638 Influencers who have used the word "stylist" in their bio description:`
        ]
      },

      {
        title: "Is it possible to identify influencers with business accounts?",
        body: [
          `Using our Account Type filter you can choose what type of accounts - Regular, Business or Creator - you would like to see in your search results.`,

          `Currently, out of the 91,750,335 Influencers in our Influencer Identification database, there are 27,503,814 business accounts and 7,510,994 creator accounts.`
        ]
      },

      {
        title: "How often do you update the database?",
        body: [
          "We fully update our database on a monthly basis."
        ]
      },

      {
        title: "Do you provide contact details of influencers?",
        body: [
          `Using our Contacts filter in the Influencer Identification tool, you can find the influencers with contact information including email, phone, Snapchat, Facebook, YouTube, Twitter, Telegram, WhatsApp, LinkedIn, VK, BBM, Kik Messenger, WeChat, Viber, Skype, Tumblr, Twitch, KaKao, Pinterest, Apple Music, Weibo, TikTok.`,

          `The list of these influencers along with their contact details can be exported in CSV reports.`
        ]
      },

      {
        title: "What is the Account Type?",
        body: [
          "The Account Type filter allows you to choose which accounts you would like to see in the list of results: ",
          [
            "Business",
            "Creator",
            "Regular (non-Business)"
          ]
        ]
      },

      {
        title: "Can I find what accounts influencers have in other social networks?",
        body: [
          `Yes, you can. By using the <b>Contacts filter</b>, you can identify the influencers that have accounts in other social media: Snapchat, Facebook, Twitter, YouTube, TikTok, etc. `,

          `The actual links to these accounts  can be fetched by exporting your results with contact details:`,

          `Note: Please keep in mind that people who can be viewed as influencers in one social network and have an account in other networks, are not necessarily influencers there as well. For instance, an Instagram influencer may have a TikTok account, but may not be considered a TikTok influencer due to the lack of followers/engagements, etc.`
        ]
      },

      {
        title: "What are the Partnerships?",
        body: [
          `The Partnerships filter allows you to identify the influencers that have published at least one sponsored post with a brand of your choice.`,

          `Example: if you introduce Nike in the Partnerships filter you can see the list of influencer that have collaborated with this brand`,

          `If you choose Any in the Partnerships filter, the results will be influencers who have done at least one sponsored post with any brand.`,

          `The filter helps you identify the accounts of the active Instagram influencers that are open to being engaged in a marketing campaign, since they have already done so in the past.`
        ]
      },

      {
        title: "What are Verified Accounts?",
        body: [
          `The Verified Accounts are the influencers that have a verified Instagram account.`
        ]
      },

      {
        title: "What are Credible Accounts?",
        body: [
          "The Credible Accounts are the influencers whose Audience Credibility is above 90%."
        ]
      },

      {
        title: "How do I deal with the repeated search results?",
        body: [
          "If you have been searching for similar topics or using similar parameters, you may come across the influencers that you have already seen (unlocked) or even exported.",

          `We <b>do not charge twice</b> for the results that you have once unlocked or exported: in case you are using tokens, we will only charge you for the new influencers that you unlock. For monthly subscription, all results are unlocked by default.`
        ]
      }
    ]
  }
]